document.addEventListener("DOMContentLoaded", () => {
    const ticketButtons = document.querySelectorAll("[data-tt-event]");
    ticketButtons.forEach((btn) => {
        const [boxOffice, eventSeriesId] = btn.dataset.ttEvent.split(",");
        const urlParams = new URLSearchParams(window.location.search);
        const accessCode = urlParams.get("access");
        const accessCodeQs = accessCode ? `access=${accessCode}` : "";
        btn.addEventListener("click", (ev) => {
            TTWidget.init();
            var url =
                "https://www.tickettailor.com/events/" +
                boxOffice +
                "/" +
                eventSeriesId +
                "/book?" +
                accessCodeQs +
                "&modal_widget=true&widget=true";
            document.getElementById("tt_widget_overlay_wrapper").style.display =
                "block";
            document.getElementsByTagName("body")[0].style.overflow = "hidden";
            TTWidget.loadUrl(url);
            document.getElementsByTagName("body")[0].style.maxHeight = "100%";
        });
    });
});
